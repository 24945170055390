import React from "react"
import { graphql } from "gatsby"
import PublicationsListingTemplate from "../components/publication-listing"

const OtherPublicationsListingTemplate = ({ data, pageContext }) => (
  <PublicationsListingTemplate data={data} pageContext={pageContext} />
)

export const query = graphql`
  query OtherPublicationsQuery($limit: Int, $skip: Int) {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        fileAbsolutePath: {
          regex: "/publications/other-publications/.*\\\\.md$/"
        }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          nid
          related_media {
            frontmatter {
              name
              path
              ext
            }
          }
        }
        html
      }
    }
  }
`

export default OtherPublicationsListingTemplate
